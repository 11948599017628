import React from "react";

const Firm = () => {
  return (
    <div className="firm">
      <p>ES FOTOGRAFIE Ewelina Sadowska</p>
      <p>Kaczorowskiego 7/313</p>
      <p>15-375 Białystok</p>
      <p>NIP 5461374623</p>
    </div>
  );
};
export default Firm;
